<template lang="pug">
q-page.full-height.scroll
  .q-pa-md.q-gutter-md
    c-dtable-devices-in-production
</template>

<style>
</style>

<script>
export default {};
</script>
